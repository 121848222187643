import { useState, useEffect, useContext } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

import Chart from "react-apexcharts";

import { roundNumber, decimalFormat, formatReportDate, getCurrencySymbol } from "utils/common";
import useJwt from "utils/jwt/useJwt";

import Block from "ui-component/Block";
import { LoaderContext } from "utils/context/ProgressLoader";

let displayCurrency = "EUR";
const generateChartOptions = (theme, labels) => {
  let totalValue = 0
  return {
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      theme.palette.common.gold,
      theme.palette.common.silver,
      theme.palette.common.goldBar,
      theme.palette.common.silverBar,
      theme.palette.common.black,
    ],
    legend: {
      show: false,
    },
    labels,
    tooltip: {
      custom: function ({ series, seriesIndex, w }) {
        return `<div style="background:#FBC34A;padding: 8px 8px;font-size: 13px;">` +
          w.globals.labels[seriesIndex] +
          ": " +
          decimalFormat(series[seriesIndex], 2) +
          "</div>"
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
          labels: {
            show: true,
            name: {
              color: "#ffffff",
            },
            value: {
              color: "#ffffff",
              formatter: function (val) {
                if (totalValue > 0) {
                  return (
                    roundNumber((parseFloat(val) * 100) / totalValue, 2) + " %"
                  );
                }
                else {
                  return roundNumber(val, 2) + " EUR";
                }
              },
            },
            total: {
              show: true,
              label: "Total",
              color: "#ffffff",
              formatter: function (w) {
                totalValue = 0
                w.config.series.forEach(element => {
                  totalValue += element;
                });
                return getCurrencySymbol(displayCurrency) + " " + decimalFormat(totalValue, 2);
              },
            },
          },
        },
      },
    },
  }
}

const CommissionReport = (props) => {
  const theme = useTheme();

  const loaderContext = useContext(LoaderContext);
  const showProgress = loaderContext.showProgress;
  const hideProgress = loaderContext.hideProgress;
  const userData = useJwt.getUserData();
  displayCurrency = userData ? userData.currency : 'EUR';

  const [chartOptions, setChartOptions] = useState(generateChartOptions(theme, []));
  const [chartSeries, setChartSeries] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [switchModeChecked, setSwitchModeChecked] = useState(false);
  const [commissionData, setCommissionData] = useState([]);
  const [monthData, setMonthData] = useState(null);
  const [yearData, setYearData] = useState(null);

  const [pendingCommissionData, setPendingCommissionData] = useState([]);
  const [pendingMonthData, setPendingMonthData] = useState(null);
  const [pendingYearData, setPendingYearData] = useState(null);


  useEffect(() => {
    loadCommissionData();
  }, []);

  const loadCommissionData = (loadMore = false) => {
    showProgress()
    useJwt
      .loadCommissionData({
        params: {
          limit: 20,
          offset: loadMore ? commissionData.length : 0,
          search_key: ''
        }
      })
      .then((res) => {
        hideProgress()
        if (res.data.ResponseCode == 0) {
          console.log('res.data', res.data);
          if (loadMore) {
            setCommissionData([...commissionData, ...res.data.ResponseResult.commissions])
          }
          else {
            setCommissionData(res.data.ResponseResult.commissions)
            if (res.data.ResponseResult.year || res.data.ResponseResult.month) {
              setMonthData(res.data.ResponseResult.month)
              setYearData(res.data.ResponseResult.year)
            }

            if (res.data.ResponseResult.pending) {
              setPendingMonthData(res.data.ResponseResult.pending.month)
              setPendingYearData(res.data.ResponseResult.pending.year)
              setPendingCommissionData(res.data.ResponseResult.pending.commissions)
            }
          }

          if (res.data.ResponseResult.commissions.length < 20) {
            setShowLoadMore(false)
          }
        }
        else {
          console.log(res.data.ResponseCode);
        }
      })
      .catch((err) => {
        hideProgress()
        console.log(err)
      });
  }

  useEffect(() => {
    if (switchModeChecked) {
      let data = pendingMonthData
      if (switchChecked) {
        data = pendingYearData
      }
      if (!data) {
        return;
      }
      console.log('data', data)

      const labels = ['Gold', 'Silver', 'Gold Bar', 'Silver Bar'];
      const currentBalances = [data.gold, data.silver, data.goldbar, data.silverbar];

      setChartOptions(generateChartOptions(theme, labels));
      setChartSeries(currentBalances);
    }
    else {
      let data = monthData
      if (switchChecked) {
        data = yearData
      }
      if (!data) {
        return;
      }
      console.log('data', data)

      const labels = ['Gold', 'Silver', 'Gold Bar', 'Silver Bar'];
      const currentBalances = [data.gold, data.silver, data.goldbar, data.silverbar];

      setChartOptions(generateChartOptions(theme, labels));
      setChartSeries(currentBalances);
    }
  },
  [monthData, yearData, switchChecked, theme, switchModeChecked]);

  const handleSwitchChange = (event) => {
    setSwitchChecked(event.target.checked);
  }

  const handleSwitchChangeMode = (event) => {
    setSwitchModeChecked(event.target.checked);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Box sx={{ flexGrow: 1 }}>
            <Block sx={{ outline: "none", borderTop: "solid 1px #202020" }}>
              <Typography component="p" variant="h1" sx={{ mb: 3, marginTop: '20px' }}>
                COMMISSION REPORT
              </Typography>

              <Grid item xs={12} sm={12} md={4}>
                <FormGroup sx={{ marginTop: '2em', marginLeft: '1em' }}>
                    <FormControlLabel control={<Switch checked={switchModeChecked} onChange={handleSwitchChangeMode} />} label="Executed / Pending" />
                  </FormGroup>
              </Grid>

              <TableContainer
                component={Box}
                sx={{ marginTop: '1em' }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="120px" align="center">Type</TableCell>
                      <TableCell width="100px" align="center">Currency</TableCell>
                      <TableCell width="50px" align="center">Amount</TableCell>
                      <TableCell align="center">Description</TableCell>
                      <TableCell width="110px" align="center">Date/Time</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
              <TableContainer
                component={Box}
                sx={{ height: "calc(100vh - 350px)", overflowY: "scroll" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    {(switchModeChecked ? pendingCommissionData : commissionData).map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell width="120px" align="center">{row.type == "buy" ? "Buying Fee" : "Monthly Fee"}</TableCell>
                          <TableCell width="100px" align="center">{row.credit_coin_currency}</TableCell>
                          <TableCell width="50px" align="center">{decimalFormat(row.fee_amount, 5)}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell width="105px">{formatReportDate(row.created_at)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {showLoadMore && (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt: 3 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    loadCommissionData(true);
                  }}
                >
                  Load More
                </Button>
              )}
            </Block>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper sx={{ height: "100%", position: "relative", p: 2, marginTop: '100px', backgroundColor: 'balck', background: 'black' }}>
            <Chart options={chartOptions} series={chartSeries} type="donut" />
            <FormGroup sx={{ marginTop: '2em', marginLeft: '5em' }}>
              <FormControlLabel control={<Switch checked={switchChecked} onChange={handleSwitchChange} />} label="This month / This year" />
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default CommissionReport;
