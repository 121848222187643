// ** Auth Endpoints
export const jwtDefaultConfig = {
  loginEndpoint: `${process.env.REACT_APP_BASE_API_URL}/auth/login`,
  forgotPasswordEndpoint: `${process.env.REACT_APP_BASE_API_URL}/auth/forgot_password`,
  resetForgotPasswordEndpoint: `${process.env.REACT_APP_BASE_API_URL}/auth/reset_forgot_password`,
  registerEndpoint: `${process.env.REACT_APP_BASE_API_URL}/auth/register`,
  refreshEndpoint: "/jwt/refresh-token",
  logoutEndpoint: `${process.env.REACT_APP_BASE_API_URL}/auth/logout`,
  resetPasswordEndpoint: `${process.env.REACT_APP_BASE_API_URL}/auth/reset_password`,
  getAllUsersEndpoint: `${process.env.REACT_APP_BASE_API_URL}/user`,
  searchUsersEndpoint: `${process.env.REACT_APP_BASE_API_URL}/user/search`,
  getAllClientsEndpoint: `${process.env.REACT_APP_BASE_API_URL}/connection/advisor_clients`,
  searchClientsEndpoint: `${process.env.REACT_APP_BASE_API_URL}/connection/search_clients`,
  loadAdvisorOverview: `${process.env.REACT_APP_BASE_API_URL}/connection/advisor_overview`,
  loadCommissionData: `${process.env.REACT_APP_BASE_API_URL}/commission/advisor_commissions`,
  loadClientInformation: `${process.env.REACT_APP_BASE_API_URL}/client/find_by_email`,
  addConnection: `${process.env.REACT_APP_BASE_API_URL}/connection`,
  removeConnection: `${process.env.REACT_APP_BASE_API_URL}/connection/remove`,
  addAdvisorEndpoint: `${process.env.REACT_APP_BASE_API_URL}/user/add_advisor`,
  deleteAdvisorEndpoint: `${process.env.REACT_APP_BASE_API_URL}/connection/delete_advisor/`,
  updateAdvisorInfoEndpoint: `${process.env.REACT_APP_BASE_API_URL}/user/update_profile`,
  updateAdvisorPhotoEndpoint: `${process.env.REACT_APP_BASE_API_URL}/user/update_photo`,
  uploadFileEndpoint: `${process.env.REACT_APP_BASE_API_URL}/user/upload`,
  loadAdvisorWorkTimes: `${process.env.REACT_APP_BASE_API_URL}/work-time`,
  setAdvisorWorkTimes: `${process.env.REACT_APP_BASE_API_URL}/work-time/set_work_times`,

  loadUnreadMessagesEndpoint: `${process.env.REACT_APP_BASE_API_URL}/message/advisor_unread_messages`,
  loadRoomsAndMessagesEndpoint: `${process.env.REACT_APP_BASE_API_URL}/message/advisor`,
  clearRoomMessagesEndpoint: `${process.env.REACT_APP_BASE_API_URL}/message/clear_room_messages/`,
  getOnlineListEndpoint: `${process.env.REACT_APP_BASE_API_URL}/online/list`,

  getAdvisorEventsEndpoint: `${process.env.REACT_APP_BASE_API_URL}/event`,
  addAdvisorEventEndpoint: `${process.env.REACT_APP_BASE_API_URL}/event`,
  updateAdvisorEventEndpoint: `${process.env.REACT_APP_BASE_API_URL}/event`,
  removeAdvisorEventEndpoint: `${process.env.REACT_APP_BASE_API_URL}/event`,

  loadConfigurationsInfoEndpoint: `${process.env.REACT_APP_BASE_API_URL}/configuration`,
  updateConfigurationsInfoEndpoint: `${process.env.REACT_APP_BASE_API_URL}/configuration/set_default_fees`,
  updateEmailFooterInfoEndpoint: `${process.env.REACT_APP_BASE_API_URL}/configuration/update_email_footer`,
  updateClientFeeEndpoint: `${process.env.REACT_APP_BASE_API_URL}/connection/update_fees`,
  clientBalancesEndpoint: `${process.env.REACT_APP_BASE_API_URL}/client/balances`,
  clientTransactionsEndpoint: `${process.env.REACT_APP_BASE_API_URL}/client/transactions`,
  clientCardTransactionsEndpoint: `${process.env.REACT_APP_BASE_API_URL}/client/card_transactions`,

  mailSendEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/send`,
  mailInboxEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/inbox`,
  mailViewEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/view`,
  mailSentEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/sent`,
  mailDraftEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/draft`,
  mailSetReadUnread: `${process.env.REACT_APP_BASE_API_URL}/email/set-read-unread`,
  mailSaveDraftEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/save-draft`,
  mailSendDraftEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/send-draft`,
  mailTrashOrDeleteEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/trash-or-delete`,
  mailTrashEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/trash`,
  mailDeleteEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/delete`,
  mailsTrashEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/trash-many`,
  mailsDeleteEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/delete-many`,
  mailRestoreEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/restore`,
  mailUploadFilesEndpoint: `${process.env.REACT_APP_BASE_API_URL}/email/upload`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageUserIDKeyName: "user_id",
  storageUserDataKeyName: "userData",
  storageRefreshTokenKeyName: "refreshToken",
};
