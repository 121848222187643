import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import useJwt from "utils/jwt/useJwt";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button,
} from "@mui/material";
import { IconArrowNarrowUp } from "@tabler/icons";
import { IconArrowNarrowDown } from "@tabler/icons";
import { decimalFormat, formatReportDate } from "utils/common";
import { LoaderContext } from "utils/context/ProgressLoader";

const AssetDropdown = styled(Select)(({ theme }) => ({
  background: theme.palette.common.black,
  color: theme.palette.text.primary,
}));

let firstLoad = true;
let currentYear = 2022;
let currentMonth = 4;

const Report = (props) => {
  const store = useSelector((state) => state.clients);
  const clientInfo = store.selectedUser;
  const dispatch = useDispatch();

  const [accountNumber, setAccountNumber] = useState(0);
  const [cardCurrency, setCardCurrency] = useState('EUR');
  const [accountOptions, setAccountOptions] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const showToast = useContext(LoaderContext).showToast
  const showProgress = useContext(LoaderContext).showProgress
  const hideProgress = useContext(LoaderContext).hideProgress

  useEffect(() => {
    if (store.balances.length == 0) return;

    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth();

    if (accountNumber == 0) {
      setAccountNumber(store.balances[0].account_number);
    }

    const balance = getBalance("Card");
    if (balance) {
      setCardCurrency(balance.currency);
    }

    setAccountOptions([
      {
        value: getBalance("Gold").account_number,
        label: `Gold - ${getBalance("Gold").account_number}`,
      },
      {
        value: getBalance("Silver").account_number,
        label: `Silver - ${getBalance("Silver").account_number}`,
      },
      {
        value: getBalance("Goldbar").account_number,
        label: `Goldbar - ${getBalance("Goldbar").account_number}`,
      },
      {
        value: getBalance("Silverbar").account_number,
        label: `Silverbar - ${getBalance("Silverbar").account_number}`,
      },
      {
        value: getBalance("Card").account_number,
        label: `Card - ${getBalance("Card").account_number}`,
      },
    ]);
  }, [store.balances]);

  useEffect(() => {
    loadTransactions(false);
  }, [accountNumber]);

  const getBalance = (type) => {
    if (store.balances.length === 0) return;

    for (let item of store.balances) {
      if (item.type === type) {
        return item;
      }
    }
    return {};
  };

  const getIndex = (accountNumber) => {
    for (let index = 0; index < store.balances.length; index++) {
      let balance = store.balances[index];
      if (balance.account_number == accountNumber) {
        return index;
      }
    }
    return 0;
  };

  const getType = (accountNumber) => {
    if (store.balances.length == 0) return "";

    for (let item of store.balances) {
      if (item.account_number == accountNumber) {
        return item.type;
      }
    }

    return "Gold";
  };

  const onChangeAsset = (value) => {
    setAccountNumber(value);
  };

  const getMonthParam = (month) => {
    if (month < 10) {
      return "0" + month;
    } else {
      return "" + month;
    }
  };

  const compareCard = (a, b) => {
    if ( a.Date > b.Date ){
      return -1;
    }
    if ( a.Date < b.Date ){
      return 1;
    }
    return 0;
  }

  const loadTransactions = (loadMore) => {
    setShowLoadMore(true);

    if (accountNumber == 0) {
      setList([]);
      return;
    }
    let data = [...list];
    if (!loadMore) {
      data = [];
      setList([]);
      currentYear = new Date().getFullYear();
      currentMonth = new Date().getMonth() + 1;
    }
    else {
      currentMonth = currentMonth - 1;
      if (currentMonth <= 0) {
        currentMonth = 12;
        currentYear = currentYear - 1;
      }
    }
    // const startIndex = 10 * (page-1) + 1;
    // const endIndex = 10 * page;
    // const params = {
    //   account_number: accountNumber,
    //   start: startIndex,
    //   end: endIndex
    // }
    showProgress()

    if (getType(accountNumber) == "Card") {
      let params = {
        card_number: accountNumber,
        card_currency: clientInfo?.bank_token?clientInfo.bank_token:'EUR_PL',
        year: currentYear,
        month: getMonthParam(currentMonth),
      };
      console.log('param', params)

      console.log("first load year, month", currentYear, currentMonth);
      let tempData = [];
      useJwt
        .loadClientCardTransactions({ params })
        .then((res) => {
          console.log("res", res);
          if (res.data.ResponseCode == 0) {
            tempData = [...res.data.ResponseResult]
            console.log("first load", tempData.length);

            currentMonth = currentMonth - 1;
            if (currentMonth <= 0) {
              currentMonth = 12;
              currentYear = currentYear - 1;
            }
            console.log("second load year, month", currentYear, currentMonth);
            params = {
              card_number: accountNumber,
              card_currency: clientInfo?.bank_token?clientInfo.bank_token:'EUR_PL',
              year: currentYear,
              month: getMonthParam(currentMonth),
            };
            useJwt
              .loadClientCardTransactions({ params })
              .then((res) => {
                if (res.data.ResponseCode == 0) {
                  tempData = [...tempData, ...res.data.ResponseResult]
                  console.log("second load", tempData.length);

                  currentMonth = currentMonth - 1;
                  if (currentMonth <= 0) {
                    currentMonth = 12;
                    currentYear = currentYear - 1;
                  }
                  console.log("third load year, month", currentYear, currentMonth);
                  params = {
                    card_number: accountNumber,
                    card_currency: clientInfo?.bank_token?clientInfo.bank_token:'EUR_PL',
                    year: currentYear,
                    month: getMonthParam(currentMonth),
                  };
                  useJwt
                    .loadClientCardTransactions({ params })
                    .then((res) => {
                      hideProgress()
                      console.log("res", res);
                      if (res.data.ResponseCode == 0) {
                        tempData = [...tempData, ...res.data.ResponseResult]
                        console.log("third load", tempData.length);
                        setList([...data, ...tempData].sort(compareCard));
                      } else {
                        setList([...data, ...tempData].sort(compareCard));
                        console.log(res.data.ResponseCode);
                        showToast("error", res.data.ResponseMessage)
                      }
                    })
                    .catch((err) => {
                      hideProgress()
                      setList([...data, ...tempData].sort(compareCard));
                      console.log(err);
                      showToast("error", "Network error")
                    });
                } else {
                  hideProgress()
                  setList([...data, ...tempData].sort(compareCard));
                  console.log(res.data.ResponseCode);
                  showToast("error", res.data.ResponseMessage)
                }
              })
              .catch((err) => {
                hideProgress()
                setList([...data, ...tempData].sort(compareCard));
                console.log(err);
                showToast("error", "Network error")
              });
          } else {
            hideProgress()
            console.log(res.data.ResponseCode);
            showToast("error", res.data.ResponseMessage)
          }
        })
        .catch((err) => {
          hideProgress()
          console.log(err);
          showToast("error", "Network error")
        });
    } else {
      const params = {
        account_number: accountNumber,
        start: data.length + 1,
        end: data.length + 20,
      };

      useJwt
        .loadClientTransactions({ params })
        .then((res) => {
          hideProgress()
          if (res.data.ResponseCode == 0) {
            if (
              res.data.ResponseResult.length > 0 &&
              res.data.ResponseResult[0].constructor !== Array
            ) {
              setList([...data, ...res.data.ResponseResult]);
            }
            else {
              setShowLoadMore(false);
              if (loadMore) {
                showToast("warning", "No more data")
              }
              else {
                showToast("warning", "No data")
              }
            }
          } else {
            console.log(res.data.ResponseCode);
            showToast("warning", res.data.ResponseMessage)
          }
        })
        .catch((err) => {
          hideProgress()
          console.log(err);
          showToast("error", "Network error")
        });
    }
  };

  const getAmountLabel = (amount, description, status) => {
    try {
      amount = parseFloat(amount)
      if ( getType(accountNumber) === 'Goldbar' || getType(accountNumber) === 'Silverbar' ) {
        if (description.includes('Buy WIRE') && status.toLowerCase() === 'pending') {
          const kgAmount = amount / 32.15
          if (kgAmount === Math.floor(kgAmount)) {
            return decimalFormat(kgAmount, 2)
          }
          else {
            return decimalFormat(amount, 2)
          }
        }

        const kgAmount = amount / 32.15
        if (kgAmount === Math.floor(kgAmount)) {
          return decimalFormat(kgAmount, 2)
        }
        else {
          return decimalFormat(kgAmount, 5)
        }
      }

      return decimalFormat(amount, 2)
    }
    catch (err) {
      console.log(amount, description, status)
    }
  }

  const renderData = () => {
    if (getType(accountNumber) == "Card") {
      return list.map((col, index) => {
        const IconTag =
          Number(col.Amount) < 0 ? (
            <IconArrowNarrowDown size={14} stroke={1} />
          ) : (
            <IconArrowNarrowUp size={12} stroke={1} />
          );
        return (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell width="65px" align="center">
              {IconTag}
            </TableCell>
            <TableCell width="50px">{decimalFormat(col.Amount, 2)}</TableCell>
            <TableCell>{col.Desciption}</TableCell>
            <TableCell width="105px">{formatReportDate(col.Date)}</TableCell>
          </TableRow>
        );
      });
    } else {
      return list.map((col, index) => {
        const IconTag =
          col.DebitCredit === 'CREDIT' || col.DebitCredit === 'Credit' ? (
            <IconArrowNarrowUp size={12} stroke={1} />
          ) : (
            <IconArrowNarrowDown size={14} stroke={1} />
          );
        return (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell width="65px" align="center">
              {IconTag}
            </TableCell>
            <TableCell width="50px">{ getAmountLabel(col.Amount, col.Description, col.TransactionStatus) }</TableCell>
            <TableCell>{col.Description}</TableCell>
            <TableCell width="105px">{formatReportDate(col.CreationDate)}</TableCell>
          </TableRow>
        );
      });
    }
  };

  return (
    <Box sx={{ height: { xs: "auto", sm: "auto", md: "calc(100vh - 67px)" } }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            p: 3,
            borderRight: { xs: "none", sm: "none", md: "solid 1px #202020" },
          }}
        >
          <Typography component="p" variant="h1">
            {store.total}
          </Typography>
          <Typography component="p" variant="body1">
            Total Clients
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            p: 3,
            borderTop: {
              xs: "solid 1px #202020",
              sm: "solid 1px #202020",
              md: "none",
            },
          }}
        >
          <FormControl fullWidth>
            <AssetDropdown
              value={accountNumber}
              onChange={(event) => {
                onChangeAsset(event.target.value);
              }}
              displayEmpty
            >
              {accountOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </AssetDropdown>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ p: 3, borderTop: "solid 1px #202020" }}
        >
          <Typography component="p" variant="h1" sx={{ mb: 3 }}>
            Reports
          </Typography>
          <TableContainer
            component={Box}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="50px">Stock</TableCell>
                  <TableCell width="50px">
                    { getType(accountNumber) === "Card" ? getBalance("Card").currency
                      : ((getType(accountNumber) === "Gold" || getType(accountNumber) === "Silver") ? "Oz" : "Kg" ) }
                  </TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell width="110px">Date/Time</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableContainer
            component={Box}
            sx={{ height: "calc(100vh - 405px)", overflowY: "scroll" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody
              >{renderData()}</TableBody>
            </Table>
          </TableContainer>

          {showLoadMore && (
            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 3 }}
              onClick={(e) => {
                e.stopPropagation();
                loadTransactions(true);
              }}
            >
              Load More
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Report;
