/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "utils/context/ProgressLoader";

const { Badge, Avatar } = require("@mui/material");

const ClientAvatar = ({ name, avatar, number, status, size, sx }) => {

  let msx = {}
  if (sx) {
    msx = sx
  }
  if (size) {
    msx.width = size
    msx.height = size
  }

  const [avatarData, setAvatarData] = useState("");
  const getImage = useContext(LoaderContext).getImage;
  const addImage = useContext(LoaderContext).addImage;

  useEffect(() => {
    if (avatar) {
      if (avatar.includes('nexerone/getProfileImage')) {
        const image = getImage(avatar)
        if (image) {
          setAvatarData(image);
        }
        else {
          axios.get(avatar)
            .then((res) => {
              let data = res.data;
              if (data) {
                addImage(avatar, data)
                setAvatarData(data);
              }
            })
            .catch((err) => console.log(err));
        }
      }
      else {
        setAvatarData(avatar);
      }
    }
  }, [avatar]);

  return (
    status === undefined ? (
      number === undefined ? (
        <Avatar alt={name} src={avatarData} sx={msx} />
      ) : (
        <Badge color="primary" badgeContent={number} overlap="circular">
          <Avatar alt={name} src={avatarData} sx={msx} />
        </Badge>
      )
    ) : (
      number === undefined ? (
        <Badge
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          color={status ? "success" : "warning"}
          badgeContent=""
          variant="dot"
          sx={{
            "& .MuiBadge-dot": {
              margin: "3px",
              height: "5px",
              minWidth: "5px",
            },
          }}
        >
          <Avatar alt={name} src={avatarData} sx={msx} />
        </Badge>
      ) : (
        <Badge color="primary" badgeContent={number} overlap="circular">
          <Badge
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            color={status ? "success" : "warning"}
            badgeContent=""
            variant="dot"
            sx={{
              "& .MuiBadge-dot": {
                margin: "3px",
                height: "5px",
                minWidth: "5px",
              },
            }}
          >
            <Avatar alt={name} src={avatarData} sx={msx} />
          </Badge>
        </Badge>
      )
    )
  );
};

export default ClientAvatar;
