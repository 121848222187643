// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const adminMenus = [
  {
    id: "advisors",
    title: "ADVISORS",
    url: "/advisors",
  },
  {
    id: "settings",
    title: "SETTINGS",
    url: "/settings",
  },
];

export default adminMenus;
