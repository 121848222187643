// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const advisorMenus = [
  {
    id: "portfolio",
    title: "PORTFOLIO",
    url: "/portfolio",
  },
  {
    id: "clients",
    title: "CLIENTS",
    url: "/clients",
  },
  {
    id: "chat",
    title: "CHAT",
    url: "/chat",
  },
  {
    id: "calendar",
    title: "CALENDAR",
    url: "/calendar",
  },
  {
    id: "email",
    title: "EMAIL",
    url: "/email",
  },
  // {
  //   id: "report",
  //   title: "REPORT",
  //   url: "/report",
  // },
  {
    id: "commission",
    title: "COMMISSION",
    url: "/commission",
  },
  // {
  //   id: "buyBullion",
  //   title: "BUY BULLION",
  //   url: "/buyBullion",
  // },
  // {
  //   id: "sellBullion",
  //   title: "SELL BULLION",
  //   url: "/sellBullion",
  // },
];

export default advisorMenus;
